@import 'node_modules/nsw-design-system/src/main';

/* // // Core libraries
// @import 'node_modules/nsw-design-system/src/global/scss/settings/settings';
// @import 'node_modules/nsw-design-system/src/global/scss/base/all';
// @import 'node_modules/nsw-design-system/src/global/scss/helpers/all';
// @import 'node_modules/nsw-design-system/src/global/scss/settings/palette';
// @import 'node_modules/nsw-design-system/src/global/scss/settings/theme';
// @import 'node_modules/nsw-design-system/src/core/all';

// // Components
// @import 'node_modules/nsw-design-system/src/components/accordion/accordion';
// @import 'node_modules/nsw-design-system/src/components/card/card';
// @import 'node_modules/nsw-design-system/src/components/in-page-alert/in-page-alert';

// Material stuff
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
 */

 
/* replicate bootstrap padding styles - start */

.d-flex {
  display: flex !important;
}


.p-0, .pt-0, .py-0 {
  padding-top: 0px !important;
}

.p-1, .pt-1, .py-1 {
  padding-top: 5px !important;
}

.p-2, .pt-2, .py-2 {
  padding-top: 10px !important;
}

.p-3, .pt-3, .py-3 {
  padding-top: 15px !important;
}

.p-4, .pt-4, .py-4 {
  padding-top: 20px !important;
}

.p-5, .pt-5, .py-5 {
  padding-top: 25px !important;
}

.p-0, .pb-0, .py-0 {
  padding-bottom: 0px !important;
}

.p-1, .pb-1, .py-1 {
  padding-bottom: 5px !important;
}

.p-2, .pb-2, .py-2 {
  padding-bottom: 10px !important;
}

.p-3, .pb-3, .py-3 {
  padding-bottom: 15px !important;
}

.p-4, .pb-4, .py-4 {
  padding-bottom: 20px !important;
}

.p-5, .pb-5, .py-5 {
  padding-bottom: 25px !important;
}

.p-0, .ps-0, .px-0 {
  padding-left: 0px !important;
}

.p-1, .ps-1, .px-1 {
  padding-left: 5px !important;
}

.p-2, .ps-2, .px-2 {
  padding-left: 10px !important;
}

.p-3, .ps-3, .px-3 {
  padding-left: 15px !important;
}

.p-4, .ps-4, .px-4 {
  padding-left: 20px !important;
}

.p-5, .ps-5, .px-5 {
  padding-left: 25px !important;
}

.p-0, .pe-0, .px-0 {
  padding-right: 0px !important;
}

.p-1, .pe-1, .px-1 {
  padding-right: 5px !important;
}

.p-2, .pe-2, .px-2 {
  padding-right: 10px !important;
}

.p-3, .pe-3, .px-3 {
  padding-right: 15px !important;
}

.p-4, .pe-4, .px-4 {
  padding-right: 20px !important;
}

.p-5, .pe-5, .px-5 {
  padding-right: 25px !important;
}




.m-0, .mt-0, .my-0 {
  margin-top: 0px !important;
}

.m-1, .mt-1, .my-1 {
  margin-top: 5px !important;
}

.m-2, .mt-2, .my-2 {
  margin-top: 10px !important;
}

.m-3, .mt-3, .my-3 {
  margin-top: 15px !important;
}

.m-4, .mt-4, .my-4 {
  margin-top: 20px !important;
}

.m-5, .mt-5, .my-5 {
  margin-top: 25px !important;
}

.m-0, .mb-0, .my-0 {
  margin-bottom: 0px !important;
}

.m-1, .mb-1, .my-1 {
  margin-bottom: 5px !important;
}

.m-2, .mb-2, .my-2 {
  margin-bottom: 10px !important;
}

.m-3, .mb-3, .my-3 {
  margin-bottom: 15px !important;
}

.m-4, .mb-4, .my-4 {
  margin-bottom: 20px !important;
}

.m-5, .mb-5, .my-5 {
  margin-bottom: 25px !important;
}

.m-0, .ms-0, .mx-0 {
  margin-left: 0px !important;
}

.m-1, .ms-1, .mx-1 {
  margin-left: 5px !important;
}

.m-2, .ms-2, .mx-2 {
  margin-left: 10px !important;
}

.m-3, .ms-3, .mx-3 {
  margin-left: 15px !important;
}

.m-4, .ms-4, .mx-4 {
  margin-left: 20px !important;
}

.m-5, .ms-5, .mx-5 {
  margin-left: 25px !important;
}

.m-0, .me-0, .mx-0 {
  margin-right: 0px !important;
}

.m-1, .me-1, .mx-1 {
  margin-right: 5px !important;
}

.m-2, .me-2, .mx-2 {
  margin-right: 10px !important;
}

.m-3, .me-3, .mx-3 {
  margin-right: 15px !important;
}

.m-4, .me-4, .mx-4 {
  margin-right: 20px !important;
}

.m-5, .me-5, .mx-5 {
  margin-right: 25px !important;
}
/* replicate bootstrap padding styles - end */



.nsw-clickable {
  cursor: pointer;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.blank-link {
  font-size: 1rem;
}

.spinner-wrapper {
  display: inline-block;
  padding-left: 30px;
  vertical-align: middle;
}